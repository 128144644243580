import { Link, useNavigate } from "react-router-dom"
import { GoogleLogin } from '@react-oauth/google';
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useState, useRef } from "react";
import { UserContext } from "../../userProvider";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
  const { t, i18n } = useTranslation();
  const language = i18n.language
  const [userGoogle, setUserGoogle] = useState()
  const [userFacebook, setUserFacebook] = useState()
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const { saveUserData, saveUserImage } = useContext(UserContext);
  const [token, setToken] = useState()
  const [loginType, setLoginType] = useState('Google')
  const [errors, setErrors] = useState({});
  const domain = 'tunglam.site'
  const LINE_LOGIN_URL = 'https://access.line.me/oauth2/v2.1/authorize';
  const CLIENT_ID = '2005726085';
  const REDIRECT_URI = `https://${domain}/${language}/callback`;
  const STATE = 'azumayahotel';
  const SCOPE = 'profile openid email';

  const inputRef1 = useRef(null);
	const inputRef2 = useRef(null);


	const handleKeyDown = (e, ref) => {
		const input = ref.current;    
		const { selectionStart, selectionEnd } = input;
	
		if (e.key === 'ArrowLeft') {
		  e.preventDefault();
		  // Move cursor left
		  if (selectionStart > 0) {
			input.setSelectionRange(selectionStart - 1, selectionStart - 1);
		  }
		} else if (e.key === 'ArrowRight') {
		  e.preventDefault();
		  // Move cursor right
		  if (selectionEnd < input.value.length) {
			input.setSelectionRange(selectionStart + 1, selectionStart + 1);
		  }
		}
	  };
  const postData = async (user) => {
    try {
      const response = await fetch(`/api/user/${loginType}/f402a287-09ae-4e28-996e-091172956718`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user), 
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      navigate(`/${language}/greeting`, {state: {responseData: responseData}})


    } catch (error) {
      console.error('Error:', error);
    }
  };
  const user = {
    email: userName,
    password: password
  }
	const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const validate = () => {
      let isValid = true;
      if (userName === '') {
        toast.error('Email field must not be empty');
        isValid = false;
      } 
      else if(!validateEmail(userName)){
        toast.error('Invalid email format')
        isValid = false
      }
      if (password === '') {
        // setErrorMessage('Password field must not be empty');
        toast.error('Password field must not be empty');
        isValid = false;
      } 
      else if (password.length < 8) {
        // setErrorMessage('Password field must be at least 8 characters')
        toast.error('Password field must be at least 8 characters')
        isValid = false
      }
      return isValid;
    };
  const postDataMail = async (user) => {
    try {
      const response = await fetch(`/api/user/75941c19-7967-4538-a911-11c89949839a`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user), 
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      if(responseData.status == 0 || responseData.status == 2){
        toast.error(responseData.msgLang[language])
      }
      else{
        navigate(`/${language}/greeting`, {state: {responseData: responseData}})
      }

    } catch (error) {
      console.error('Error:', error);
  }
};
const handleEmailLogin = (e) => {
  e.preventDefault()
  if(validate()) {
    postDataMail(user)
  }
}
  const handleLineLogin = () => {
    const loginUrl = `${LINE_LOGIN_URL}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${STATE}&scope=${SCOPE}`;
    window.location.href = loginUrl;
  };

  const handleLoginSuccess = async (credentialResponse) => {
    // console.log('Google Login Success:', credentialResponse);
    const { credential } = credentialResponse;
    const base64Url = credential.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const user = JSON.parse(jsonPayload);
    setToken(credentialResponse.credential)
    setUserGoogle(user)
    saveUserData(user);
    saveUserImage(user.picture)
    postData(user)
    
  };

  const handleLoginFailure = (error) => {
    console.error('Google Login Failed:', error);
  };
  useGoogleOneTapLogin({
    onSuccess: credentialResponse => {
      console.log(credentialResponse);
    const { credential } = credentialResponse;
    const base64Url = credential.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const user = JSON.parse(jsonPayload);
    setToken(credentialResponse.credential)
    setUserGoogle(user)
    saveUserData(user);
    saveUserImage(user.picture)
    postData(user)

    },
    onError: () => {
      console.log('Login Failed');
    },
  });

  const handleFacebookCallback = (user) => {
      if (user?.status === "unknown") {
          console.error('Sorry!', 'Something went wrong with facebook Login.');
      }
      setToken(user.accessToken)
      setUserFacebook(user)
      saveUserData(user);
      saveUserImage(user.picture.data.url)
      postData(user)
  }
    return(
<div className="container_login container">
  <div className="left_login">
     <div className="login_header">
     <div className="login_title animation a0">
          <img src="https://azumayavietnam.com/image/logo/logo_az_k_bg.png">
          </img>
          </div>
      <h3 className=" mt-3 animation a1">{t("home.welcome_title")}</h3>
      <h4 className="animation a2">{t("login.welcome_login")}</h4>
     </div>
		<form className="login_card-form">
			<div className="login_input animation a3">
				<input
        ref={inputRef1}
        onKeyDown={(e) => handleKeyDown(e, inputRef1)}
         type="text" className="login_input-field" 
         required
         value={userName}
         onChange={(e) => setUserName(e.target.value)}
         />
				<label className="login_input-label ml-0">{t("room_hbt1.emailTitle")}</label>
			</div>
			<div className="login_input animation a4">
				<input 
        ref={inputRef2}
        onKeyDown={(e) => handleKeyDown(e, inputRef2)} 
        type="password" 
        className="login_input-field"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        />
				<label className="login_input-label ml-0">{t("login.password")}</label> 
			</div>
			<div className="mt-3 animation a5">
				<button className="action-button" onClick={handleEmailLogin}>{t("login.login")}</button>
			</div>
      <div className="login__footer d-flex mt-3 justify-content-between align-items-center animation a7">
         <a className="forgot-pass" href={`/${language}/forgot-password`}>{t("login.forgot")} ?</a>
          </div>
		</form>
		<div className="login_card-info animation a8">
    <div className="d-flex justify-content-center">
    <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
          useOneTap
        />
    </div>
      <div className="d-flex justify-content-center">
       <FacebookLogin 
      buttonStyle={{padding:"6px"}}
      appId="488228897270486"
      autoLoad={false}  
      fields="name, email, picture.width(200).height(200)" 
      callback={handleFacebookCallback}
      render={(renderProps) => (
        <button onClick={() => {
          renderProps.onClick()
          setLoginType("Facebook")
        }} style={{ backgroundColor: '#0866FF', fontSize: '1.4rem', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '3px', width: '214px', height: '30px', marginTop: '5px', display: 'flex', alignItems:'center', justifyContent:'center' }}>
          <i class="fa-brands fa-facebook mr-3" style={{fontSize: '1.8rem'}}></i>{t("login.facebook")}
        </button>
      )}
      />
      </div>
      <div className="d-flex justify-content-center">
      <button onClick={handleLineLogin} style={{ backgroundColor: '#06C755', fontSize: '1.4rem', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '3px', width: '214px', height: '30px', marginTop: '5px', display: 'flex', alignItems:'center', justifyContent:'center' }}>
          <i class="fa-brands fa-line mr-3" style={{fontSize: '1.8rem'}}></i>{t("login.line")}
        </button>
      </div>
		</div>
    <div className="animation a9">
			<p  style={{fontSize:"1.4rem"}}>{t("login.noacc")} ? <Link to ="/register" style={{color:"#482979", fontWeight:600}}>{t("login.signup")}</Link></p>
  </div>
  </div>
  <div className="right_login" style={{backgroundImage:`url(https://azumayavietnam.com/image/rtbr/rtbrdn3.jpeg)`}}>
  </div>
  <ToastContainer 
         position="top-left"
         transition={Flip}
         />
</div>
    )
}