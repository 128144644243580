import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ForgotPassword() {
    const [userName, setUserName] = useState('')
    const {t, i18n} = useTranslation()
    const language = i18n.language || 'en'
    const navigate = useNavigate()
    const [show, setShow] = useState(true)
    const [show2, setShow2] = useState(false)
    const domain = 'tunglam.site'
    const data = 
        {
            email: userName,
            language: language
        }
        const inputRef1 = useRef(null);
      
        const handleKeyDownArrow = (e, ref) => {
          const input = ref.current;    
          const { selectionStart, selectionEnd } = input;
        
          if (e.key === 'ArrowLeft') {
            e.preventDefault();
            // Move cursor left
            if (selectionStart > 0) {
            input.setSelectionRange(selectionStart - 1, selectionStart - 1);
            }
          } else if (e.key === 'ArrowRight') {
            e.preventDefault();
            // Move cursor right
            if (selectionEnd < input.value.length) {
            input.setSelectionRange(selectionStart + 1, selectionStart + 1);
            }
          }
          };
   const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const validate = () => {
      let isValid = true;
      if (userName === '') {
        toast.error('Email field must not be empty');
        isValid = false;
      } 
      else if(!validateEmail(userName)){
        toast.error('Invalid email format')
        isValid = false
      }
      return isValid;
    };

    const postData = async (data) => {
        try {
          const response = await fetch(`/api/user/b4e351bc-ff4e-4d49-a50e-994c079abc4a`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)

          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const responseData = await response.json();
          if(responseData.status === 0) {
            toast.error(responseData.msgLang[language])
          } else {
            toast.success(responseData.msgLang[language])
            setShow(false)
            setShow2(true)
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
     
      const handleKeyDown = (event) => {
        if(event.key === 'Enter') {
              event.preventDefault()
              postData(data)
          }
      };

      const handlePostData = () => {
        if (validate()){
          postData(data)
        }
      }
    return(
        <div className="d-flex justify-content-center align-items-center"style={{ height: '100vh', border: '1px solid #482979d'}}>
        {show && (
        <div>
                <div className="login_header">
                <div className="login_title animation a0">
                    <img style={{width: 330, height:140}} src="https://azumayavietnam.com/image/logo/logo_az_k_bg.png">
                    </img>
                    </div>
                <h4 className="animation a2 mt-3 ml-2">Please input your email</h4>
                </div>
                    <form className="login_card-form mt-3">
                        <div className="login_input animation a4">
                            <input
                                ref={inputRef1}
                                type="text" className="login_input-field" 
                                required
                                value={userName}
                                onChange={(e) => {
                                  setUserName(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                   handleKeyDown(e)
                                   handleKeyDownArrow(e, inputRef1)
                                }}
                                />
                            <label className="login_input-label ml-0">Email</label>
                        </div>
                    </form>
                    <div className="login_card-info animation a1">
                <div className="d-flex justify-content-center">
                </div>
                <div className="d-flex justify-content-center">
                    <button className="forgot-pass-btn" onClick={handlePostData}><i class="fa-solid fa-arrow-right"></i></button>
                </div>
                </div>
                </div>
        )}
        {show2 && (
            <div className="d-flex flex-column m-5">
            <div className="animation a1" style={{width: 60, height: 50, position: 'relative'}}>
             <i class="fa-solid fa-envelope" style={{fontSize: '5rem', color:'#482979'}}></i>
             <i class="fa-solid fa-circle-check" style={{fontSize: '2.5rem', position:'absolute', right: 5, bottom: 0, color:'green', backgroundColor: '#fff', borderRadius: '100%'}}></i>
            </div>
             <h4 className="mt-3 ml-2 animation a2">An email has been sent to your address with instructions to reset your password. <br/>Please check your inbox or click <a href="https://mail.google.com/mail/u/0/#inbox">here</a> to redirect to Gmail !</h4>
            </div>
        )}
         <ToastContainer 
          position="top-left"
         transition={Flip}
         />
        </div>
    )
}