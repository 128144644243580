import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export default function Recruitment() {
    const {t, i18n} = useTranslation()
    const language = i18n.language
    const [jobData, setJobData] = useState()
    console.log("🚀 ~ Recruitment ~ recruitmentData:", jobData)
    const getData = async () => {
      try {
        const response = await fetch(`/api/recruitment/856c6d86-5d89-411a-aff5-2cbb9d3bfa04`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        setJobData(responseData)   
      } 
      catch (error) {
        console.error('Error:', error);
      }
  }
  useEffect(() => {
    getData()
  }, [])
  const groupJobsByPosition = (data) => {
    const grouped = {}; // Initialize an empty object to store grouped jobs by position.
  
    // Object.values(data) extracts all the job arrays from the input 'data' object.
    Object.values(data).forEach((jobs) => {
      
      // Iterate over each job within the array of jobs.
      jobs.forEach((job) => {
        
        // Check if the 'grouped' object already has an array for the current job's position.
        if (!grouped[job.position]) {
          
          // If not, create an empty array for this position.
          grouped[job.position] = [];
        }
        
        // Push the current job into the array for its position.
        grouped[job.position].push(job);
      });
    });
    
    // Return the grouped jobs object.
    return grouped;
};
  const groupedJobs = groupJobsByPosition(jobData?.data || {});
  console.log("🚀 ~ Recruitment ~ groupedJobs:", groupedJobs)

    return (
    <>
     <div className="recruitment_header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Tuyển Dụng</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to={`/${language}/`}>
                  <i className="fa-solid fa-house"></i>
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link to = {`/${language}/recruitment/`} className="breadcrumb__title">
                  Tuyển Dụng 
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
        <div className="d-flex justify-content-center">
            <div style={{fontSize: "3.5rem", fontWeight:'bold', color:"#482979", borderBottom: "3px solid #482979"}}>Tại sao nên làm việc tại Azumaya ?</div>
        </div>
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-7 d-flex align-items-center">
            <div style={{fontSize: "1.4rem" }}> Azumaya Hotel là một thương hiệu khách sạn kiểu Nhật, dành cho thương gia có mặt tại Tp. Hồ Chí Minh, Việt Nam từ giữa năm 2011. Khách sạn hoạt động với mục tiêu tạo cho khách hàng Nhật sự thoải mái và cảm giác an toàn như ở nhà, bằng những tiện nghi và dịch vụ mang phong cách Nhật Bản. 
            <br/><br />Năm 2012, Azumaya mở rộng thêm với các chi nhánh ở Hà Nội, đã đem đến một không gian Nhật, một phong cách Nhật, làm hài lòng nhiều khách hàng tại đây.
            <br/><br/> Từ năm 2013 đến nay, Azumaya Hotel đã khai trương thêm các chi nhánh ở TP. HCM, Hà Nội, Đà Nẵng, Hải Phòng và thị trường Đông Nam Á như Campuchia, Myanmar nhằm khẳng định vị trí của Azumaya trong việc phục vụ đối tượng khách hàng tương đối khó tính này. Với nỗ lực cải thiện dịch vụ hằng ngày, chúng tôi tin rằng làm việc tại Azumaya sẽ mang đến cho bạn những bước phát triển mới trong ngành nghề mà ít có khách sạn nào có được.
            <br/><br/> Hãy gia nhập team Azumaya  để trở thành 1 phần quan trọng không thể thiếu của công ty chúng tôi.
            </div>
                </div>
            <div className="col-md-5 d-flex justify-content-center align-items-center mt-3">
            <img src="https://azumayavietnam.com/image/hcm/tvl1/facility/LBTVL1_4.webp" width={450} height={300}/>
            </div>
            </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
            <div style={{fontSize: "3.5rem", fontWeight:'bold', color:"#482979", borderBottom: "3px solid #482979"}}>Các vị trí đang tuyển tại Azumaya Việt Nam</div>
        </div>
        <div className="cointainer mt-5">
                    <div className="table_container">
                      <table className="room__tariff">
                        <tbody>
                          <tr>
                            <th
                              style={{ width: "40%" }}
                            >
                              Vị trí tuyển dụng
                            </th>
                            <th style={{ width: "20%" }}>
                              Nơi làm việc
                            </th>
                            <th style={{ width: "20%" }}>
                              Ngày hết hạn
                            </th>
                          </tr>
                          <tr>
                          </tr>
                          {Object.entries(groupedJobs).map(([position, jobs]) => (
                              jobs.map((job, index) => (
                                <tr key={job._id}>
                                  {index === 0 && (
                                    <td
                                      className="pl-4"
                                      style={{ textAlign: 'left', fontWeight: 'bold' }}
                                      rowSpan={jobs.length}
                                    >
                                      {position}
                                    </td>
                                  )}
                                  <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {job.location}
                                  </td>
                                  <td>{new Date(job.deadline).toLocaleDateString()}</td>
                                </tr>
                              ))
                            ))}
                        </tbody>
                      </table>
        </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
            <div style={{fontSize: "3.5rem", fontWeight:'bold', color:"#482979", borderBottom: "3px solid #482979"}}>Cơ hội được làm việc thực tế tại Nhật Bản</div>
        </div>
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-5 d-flex justify-content-center align-items-center">
                    <img src="https://www.hilton.com/im/en/NoHotel/19361896/shutterstock-667925704.jpg?impolicy=crop&cw=3800&ch=2533&gravity=NorthWest&xposition=0&yposition=1&rw=1280&rh=856" width={450} height={300}/>
                    {/* <img src="https://www.state.gov/wp-content/uploads/2019/04/Japan-2107x1406.jpg" width={400} height={300}/> */}
                </div>
                <div className="col-md-7 d-flex align-items-center mt-3">
            <div style={{fontSize: "1.4rem" }}>Nhật Bản là một đất nước phát triển với nhiều đặc điểm văn hoá đặc sắc cũng như nhiều triết lý kinh doanh đáng để cả thế giới phải học hỏi.
            <br/><br/>Giá trị và sự thành công của chúng tôi bắt nguồn từ đặc trưng rất quan trọng trong ngành dịch vụ Nhật Bản đó là "Omotenashi - Lòng hiếu khách".
            <br/><br/> Thông qua việc trao cơ hội được tận mắt thấy được tinh thần Omotenashi trong ngành dịch vụ nói riêng và đời sống thường ngày tại Nhật nói chung, chúng tôi tin rằng các bạn có thể học hỏi và phát triển nhiều hơn nữa. Với phương châm "Chọn đúng người - Gửi đúng cách - Làm tại đúng công ty", chúng tôi liên kết trực tiếp với các hệ thống khách sạn/Ryokan/nhà hàng tại Nhật mà không qua bất cứ bên trung gian nào. Bởi vậy, sẽ tiết kiệm được những chi phí không cần thiết trong quá trình đến Nhật.
            <br/><br/>Hãy để Azumaya trang bị cho bạn một hành trang thật vững chắc trên con đường hướng tới đất nước mặt trời mọc bạn nhé.
            </div>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
            <div style={{fontSize: "3.5rem", fontWeight:'bold', color:"#482979", borderBottom: "3px solid #482979"}}>Thông tin tuyển dụng</div>
        </div>
        <div className="container mt-5">
            <div style={{fontSize: "1.4rem" }}> Khu vực Hà Nội: Mrs. Diệu Linh <i class="fa-solid fa-phone purple ml-3 mr-1"></i> <a href="tel:096 507 9838">096 507 9838</a> <i class="fa-solid fa-envelope purple ml-3 mr-2"></i> <a href="mailto:azhn.recruitment@azumayavietnam.com">azhn.recruitment@azumayavietnam.com</a></div>
        </div>
        <div className="container mt-5">
            <div style={{fontSize: "1.4rem" }}> Khu vực Hồ Chí Minh: Ms. Trang <i class="fa-solid fa-phone purple ml-3 mr-1"></i> <a href="tel:076 559 2669">076 559 2669</a> <i class="fa-solid fa-envelope purple ml-3 mr-2"></i> <a href="mailto:adminhcm@azumayavietnam.com">adminhcm@azumayavietnam.com</a></div>
        </div>
        <div className="container mt-5">
            <div style={{fontSize: "1.4rem" }}> Khu vực Đà Nẵng: Ms. Hưng <i class="fa-solid fa-phone purple ml-3 mr-1"></i> <a href="tel:0236 3743 888">0236 3743 888</a> <i class="fa-solid fa-envelope purple ml-3 mr-2"></i> <a href="mailto:managerdanang@azumayavietnam.com">managerdanang@azumayavietnam.com</a></div>
        </div>
        <div className="container mt-5">
            <div style={{fontSize: "1.4rem" }}> Khu vực Hải Phòng: Ms. Nhi <i class="fa-solid fa-phone purple ml-3 mr-1"></i> <a href="tel:0225 3828 969">0225 3828 969</a> <i class="fa-solid fa-envelope purple ml-3 mr-2"></i> <a href="mailto:managerhaiphong1@azumayavietnam.com">managerhaiphong1@azumayavietnam.com</a></div>
        </div>
        </div>
    </>
    )
}